import { useEffect, useState } from "react";
import Tabla from "../../components/table";
import tokenExpired from "../../helpers/tokenExpired";
import authService from "../../services/api/authService";
import UserHttp from "../../services/api/userService";
import AddButtonModal from "./actions/add";
import DeleteButtonModal from "./actions/delete";
import EditButtonModal from "./actions/edit";

export default function Usuarios() {
  const [usuarios, setUsuarios] = useState([]);

  const getData = async () => {
    UserHttp.getAll({ token: authService.getToken(), withDeleted: false })
      .then((response) => {
        setUsuarios(response.data.data);
      })
      .catch((error) => {
        tokenExpired(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { label: "Nombre de usuario", id: "username" },
    { label: "Correo", id: "email" },
    { label: "Acciones", buttons: [EditButtonModal, DeleteButtonModal] },
  ];
  return (
    <div>
      <AddButtonModal refreshFunction={getData} />
      <Tabla data={usuarios} columns={columns} refreshFunction={getData} />
    </div>
  );
}
