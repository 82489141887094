import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import estacionService from "../../services/api/estacionService";
import dayService from "../../services/api/dayService";
import { Button, Grid, InputLabel } from "@mui/material";
import ButtonUpload from "../UploadExcel/index.js";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelectPlaceholder({ getPrograms, establecerDia, refresh }) {
  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    getData();
  }, [refresh]);

  const getData = async () => {
    const {
      data: { data: estaciones },
    } = await estacionService.getAll();
    setEstaciones(estaciones);
    // console.log(estaciones)

    const {
      data: { data: dias },
    } = await dayService.getAll();
    setDays(dias);
  };

  const [estaciones, setEstaciones] = React.useState([]);
  const [days, setDays] = React.useState([]);
  const [daysfiltrado, setDaysFiltrado] = React.useState([]);

  const [idEstacion, setIdEstacion] = React.useState("");
  const [idDia, setIdDia] = React.useState("");

  const handleChanges = (event) => {
    const { target } = event;
    setIdEstacion(target.value);
    const state = estaciones.find((e) => e._id === target.value);
    setDaysFiltrado([]);
    setIdDia("");
    if (state) {
      state.days.forEach((d, i) => {
        const day = days.find((dia) => dia._id === d);
        if (day) {
          setDaysFiltrado((daysfiltrado) => [...daysfiltrado, day]);
        }
      });
    }
  };
  const handleChangesDay = (event) => {
    const { target } = event;
    const day = days.find((dia) => dia._id === target.value);
    console.log({ day });
    establecerDia({ day });
    setIdDia(target.value);
    getPrograms({ id_day: target.value, emitions: day.emitions });
  };

  return (
    <>
      <InputLabel>Seleciona una estación</InputLabel>
      <FormControl sx={{ mb: 4 }} fullWidth>
        <Select
          value={idEstacion}
          onChange={handleChanges}
          input={<OutlinedInput />}
          label="Seleccione + estacion"
          MenuProps={MenuProps}
          // inputProps={{ "aria-label": "Without label"
          //}}
        >
          {estaciones.map((elemento, index) => (
            <MenuItem key={index} value={elemento._id}>
              {elemento.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {daysfiltrado.length !== 0 && (
        <>
          <InputLabel>Seleciona un día</InputLabel>
          <FormControl sx={{ mb: 4 }} fullWidth>
            <Select
              value={idDia}
              onChange={handleChangesDay}
              input={<OutlinedInput />}
              label="Seleccione un dia"
              MenuProps={MenuProps}
              // inputProps={{ "aria-label": "Without label" }}
            >
              {daysfiltrado.map((elemento, index) => (
                <MenuItem key={index} value={elemento._id}>
                  {elemento.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Grid>
            <ButtonUpload daysfiltrado={daysfiltrado} />
          </Grid>
        </>
      )}
    </>
  );
}
