import React, { useEffect, useState } from "react";
import Tabla from "../../components/table";
import AddButtonModal from "./actions/add";
import DeleteButtonModal from "./actions/delete";
import EditButtonModal from "./actions/edit";
import EstacionHttp from "../../services/api/estacionService";
import tokenExpired from "../../helpers/tokenExpired";

export default function Estaciones() {
  const [estaciones, setEstaciones] = useState([]);

  const getData = async () => {
    EstacionHttp.getAll()
      .then((response) => {
        setEstaciones(response.data.data);
      })
      .catch((error) => {
        tokenExpired(error)
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { label: "Estacion", id: "name" },
    { label: "Descripcion", id: "description" },
    { label: "url", id: "url" },
    { label: "Acciones", buttons: [EditButtonModal, DeleteButtonModal] },
  ];

  return (
    <div>
      <AddButtonModal refreshFunction={getData} />
      <Tabla data={estaciones} columns={columns} refreshFunction={getData} />
    </div>
  );
}
