import http from "./index";

const getAll = (query) => {
  return http.get("./emisiones", { params: query });
};

const getOne = (id) => {
  return http.get(`./emisiones/${id}`);
};

const create = (data_send) => {
  return http.post("./emisiones", data_send);
};

const update = (id, data_send) => {
  return http.put(`./emisiones/${id}`, data_send);
};

const remove = (id) => {
  return http.delete(`./emisiones/${id}`);
};

const emisionService = {
  getAll,
  getOne,
  create,
  update,
  remove,
};

export default emisionService;
