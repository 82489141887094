import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import ProgramaHttp from "../../../services/api/programaService";
import EmisionHttp from "../../../services/api/emisionService";
import Alert from "../../../components/alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function DeleteButtonModal({ row, refreshFunction }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "success",
  });
  // const [data_send] = React.useState({ ...row });
  const data = { ...row };
  const getFomrData = { FormData };
  const handleDelete = () => {
    EmisionHttp.remove(data._id)
      .then((response) => {
        // throw { message: "test error" };
        setSnack({
          open: true,
          message: "Programa Eliminado!!",
        });
        handleClose();
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
          refreshFunction();
        }, 2000);
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        handleClose();
        console.log(error);
      });
  };

  return (
    <div>
      {/* <IconButton sx={{ width: "1px", height: "20px" }} onClick={handleOpen}> */}
      <Tooltip title="Eliminar">
        <IconButton onClick={handleOpen}>
          <DeleteIcon sx={{ color: "rgb(196,27,27)" }} />
        </IconButton>
      </Tooltip>
      {/* </IconButton> */}
      {snack.open && <Alert message={snack.message} Popen={snack.open} type={snack.type} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h5" component="h2">
              Eliminar Programa
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              ¿Deseas eliminar a {getFomrData.name}?
            </Typography>
            <Box sx={{ display: "flex" }}>
              <LoadingButton
                size="small"
                color="secondary"
                onClick={() => handleClose()}
                variant="contained"
                sx={{ ml: 28, mt: 2 }}
              >
                Cancelar
              </LoadingButton>
              <LoadingButton
                size="small"
                color="secondary"
                onClick={() => handleDelete()}
                loadingPosition="start"
                startIcon={<DeleteIcon />}
                variant="contained"
                sx={{ ml: "auto", mt: 2 }}
              >
                Eliminar
              </LoadingButton>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
