import * as React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

const OnlyLetters = /[azAZ]+/;

export default function FormAdd({
  data_send,
  setData_send,
  handleAction,
  handleClose,
  title,
  ButtonForm,
}) {
  const action = async () => {
    handleAction();
  };

  return (
    <div>
      <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
        {title}
      </Typography>
      <TextField
        name="username"
        fullWidth
        defaultValue={data_send.username}
        required
        error={data_send.username === ""}
        helperText={data_send.username === "" && OnlyLetters ? "Nombre es requerido" : ""}
        label="Nombre"
        sx={{ mr: 3, mb: 3 }}
        onChange={({ target }) => setData_send({ ...data_send, username: target.value })}
      />

      <TextField
        fullWidth
        defaultValue={data_send.email}
        id="outlined-multiline-flexible"
        label="Correo"
        onChange={({ target }) => setData_send({ ...data_send, email: target.value })}
        maxRows={4}
        rows={4}
        sx={{ mr: 3 }}
      />
      <TextField
        name="password"
        type={"password"}
        defaultValue={data_send.password}
        fullWidth
        label="Password"
        sx={{ mt: 3, mb: 3 }}
        onChange={({ target }) => setData_send({ ...data_send, password: target.value })}
      />
      <Box sx={{ display: "flex" }}>
        <LoadingButton
          size="small"
          color="secondary"
          onClick={() => handleClose()}
          /* loadingPosition="start" */
          variant="contained"
          sx={{ ml: 27, mt: 1 }}
        >
          {ButtonForm.textCancel}
        </LoadingButton>
        <LoadingButton
          size="small"
          color="secondary"
          onClick={() => action()}
          /* loadingPosition="start" */
          startIcon={ButtonForm.icon}
          variant="contained"
          sx={{ ml: "auto", mt: 1 }}
        >
          {ButtonForm.text}
        </LoadingButton>
      </Box>
    </div>
  );
}
