import React, { useEffect, useState } from "react";
import Tabla from "../../components/table";
import AddButtonModal from "./actions/add";
import DeleteButtonModal from "./actions/delete";
import EditButtonModal from "./actions/edit";
import ProgramHttp from "../../services/api/programaService";
import authService from "../../services/api/authService";
import tokenExpired from "../../helpers/tokenExpired";

export default function Programas() {
  const [programas, setProgramas] = useState([]);

  const getData = async () => {
    ProgramHttp.getAll({ token: authService.getToken() })
      .then((response) => {
        setProgramas(response.data.data);
      })
      .catch((error) => {
        tokenExpired(error)
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { label: "Nombre del programa", id: "name" },
    { label: "Descripcion", id: "description" },
    { label: "Acciones", buttons: [EditButtonModal, DeleteButtonModal] },
  ];

  return (
    <div>
      <AddButtonModal refreshFunction={getData} />
      <Tabla data={programas} columns={columns} refreshFunction={getData} />
    </div>
  );
}
