import React, { useEffect, useState } from "react";
import EmisionHttp from "../../services/api/emisionService";
import Tabla from "../../components/table";
import AddButtonModal from "./actions/add";
import { hourPretty } from "../../helpers/hourPretty";
import EditButtonModal from "./actions/edit";
import DeleteButtonModal from "./actions/delete";
import DeleteButton from "./actions/delete";
import authService from "../../services/api/authService";
import tokenExpired from "../../helpers/tokenExpired";

export default function Emisiones() {
  const [emisiones, setEmisiones] = useState([]);

  const getData = async () => {
    EmisionHttp.getAll({ token: authService.getToken() })
      .then((response) => {
        setEmisiones(response.data.data);
      })
      .catch((error) => {
        tokenExpired(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  emisiones.map((emision) => {
    emision.time_start = hourPretty({ number: emision.time_start });
    emision.time_end = hourPretty({ number: emision.time_end });
    return emision;
  });

  const columns = [
    { label: "Programa", id: "program_name" },
    { label: "Hora inicial", id: "time_start" },
    { label: "Hora final", id: "time_end" },
    { label: "Acciones", id: "actions", buttons: [EditButtonModal, DeleteButton] },
  ];

  return (
    <div>
      <AddButtonModal refreshFunction={getData} />
      <Tabla data={emisiones} columns={columns} refreshFunction={getData} />
    </div>
  );
}
