import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Routes, Route, Outlet } from "react-router-dom";
import MiniDrawer from "./components/Drawer";
import Programas from "./routes/programas";
import Estaciones from "./routes/estaciones";
import Emisiones from "./routes/emisiones";
import Horarios from "./routes/horarios";
import Usuarios from "./routes/usuarios";
import Login from "./routes/auth";
import Logout from "./routes/auth/logout";
import { PrivateRoute } from "./components/PrivateRoute";
// import UploadExcel from "./components/UploadExcel";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function App() {
  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Horarios />} />
        <Route path="programas" element={<Programas />} />
        <Route path="estaciones" element={<Estaciones />} />
        <Route path="emisiones" element={<Emisiones />} />
        <Route path="usuarios" element={<Usuarios />} />
        <Route path="logout" element={<Logout />} />
        {/* <Route path="upload" element={<UploadExcel />} /> */}
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <PrivateRoute>
      <div>
        <Box sx={{ display: "flex" }}>
          <MiniDrawer />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Outlet />
          </Box>
        </Box>
      </div>
    </PrivateRoute>
  );
}
