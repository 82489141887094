import http from "./index";

const getAll = ({ withDeleted }) => {
  return http.get("./user", { params: { withDeleted } });
};

const getOne = (id) => {
  return http.get(`./user/${id}`);
};

const create = (data_send) => {
  return http.post("./user", data_send);
};

const update = (id, data_send) => {
  return http.put(`./user/${id}`, data_send);
};

const remove = (id) => {
  return http.delete(`./user/${id}`);
};

const userService = {
  getAll,
  getOne,
  create,
  update,
  remove,
};

export default userService;
