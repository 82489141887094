import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Form from "./form";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Alert from "../../../components/alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Title = "Editar emisión";

const ButtonForm = {
  text: "Editar",
  icon: <EditOutlinedIcon />,
  textCancel: "Cancelar",
};

export default function EditButtonModal({ program, editProgram, position }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    console.log("EditButtonModal", program);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "success",
  });

  const handleEdit = ({ formData }) => {
    editProgram(formData, position);
    handleClose();
  };

  return (
    <div>
      <Tooltip title="Editar">
        <IconButton onClick={handleOpen}>
          <EditIcon sx={{ color: "rgb(240,176,0)" }} />
        </IconButton>
      </Tooltip>
      {snack.open && <Alert message={snack.message} Popen={snack.open} type={snack.type} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Form
              data={program}
              title={Title}
              ButtonForm={ButtonForm}
              handleAction={handleEdit}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
