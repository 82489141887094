import http from "./index";

const getAll = () => {
  return http.get("./days");
};

const getOne = (id) => {
  return http.get(`./days/${id}`);
};

const create = (data_send) => {
  return http.post("./days", data_send);
};

const update = (id, data_send) => {
  return http.put(`./days/${id}`, data_send);
};

const remove = (id) => {
  return http.delete(`./days/${id}`);
};

const order = (id, data) => {
  return http.put(`./days/${id}/order-emisiones`, { emisiones: data });
};

const saveEmitions = (id, data) => {
  return http.put(`./days/${id}/save-emisiones`, { emitions: data });
};

const SaveEmitionsBulk = (data) => {
  return http.post(`./days/save-emitions-bulk`, data);
};

const dayService = {
  getAll,
  getOne,
  create,
  update,
  remove,
  order,
  saveEmitions,
  SaveEmitionsBulk,
};

export default dayService;
