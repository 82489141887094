import * as React from "react";
import { Box, Stack, Switch, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { pink } from "@mui/material/colors";
import { alpha, styled } from "@mui/material/styles";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: pink[900],
    "&:hover": {
      backgroundColor: alpha(pink[900], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: pink[900],
  },
}));

export default function FormAdd({
  data_send,
  setData_send,
  handleAction,
  handleClose,
  title,
  ButtonForm,
}) {
  const action = async () => {
    handleAction();
  };

  return (
    <div>
      <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
        {title}
      </Typography>
      <TextField
        fullWidth
        defaultValue={data_send.name}
        required
        error={data_send.name === ""}
        helperText={data_send.name === "" ? "Nombre es requerido" : ""}
        id="demo-helper-text-aligned-no-helper"
        label="Nombre"
        sx={{ mr: 3, mb: 3 }}
        onChange={({ target }) => setData_send({ ...data_send, name: target.value })}
      />
      <TextField
        fullWidth
        defaultValue={data_send.description}
        id="outlined-multiline-flexible"
        label="Descripcion"
        onChange={({ target }) => setData_send({ ...data_send, description: target.value })}
        multiline
        maxRows={4}
        rows={4}
        sx={{ mr: 3 }}
      />
      <TextField
        fullWidth
        defaultValue={data_send.url}
        required
        error={data_send.url === ""}
        helperText={data_send.url === "" ? "URL es requerida" : ""}
        id="demo-helper-text-aligned-no-helper"
        label="URL"
        sx={{ mt: 3, mr: 3 }}
        onChange={({ target }) => setData_send({ ...data_send, url: target.value })}
      />
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{ mt: 3, ml: 1 }}
        onChange={({ target }) => setData_send({ ...data_send, active: target.checked })}
      >
        <Typography color="text.secondary">Activo</Typography>
        <GreenSwitch defaultChecked={data_send.active} />
      </Stack>
      <Box sx={{ display: "flex" }}>
        <LoadingButton
          size="small"
          color="secondary"
          onClick={() => handleClose()}
          /* loadingPosition="start" */
          variant="contained"
          sx={{ ml: 27, mt: 1 }}
        >
          {ButtonForm.textCancel}
        </LoadingButton>
        <LoadingButton
          size="small"
          color="secondary"
          onClick={() => action()}
          /* loadingPosition="start" */
          startIcon={ButtonForm.icon}
          variant="contained"
          sx={{ ml: "auto", mt: 1 }}
        >
          {ButtonForm.text}
        </LoadingButton>
      </Box>
    </div>
  );
}
