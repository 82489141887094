import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import XLSX from "xlsx";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DayHttp from "../../services/api/dayService";
import Alert from "../alert/index";
import DialogPrograms from "./dialog";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function UploadExcel({ daysfiltrado }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState({
    open: false,
    data: "",
  });

  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  const [data, setData] = React.useState([]);

  const handleFile = async (e) => {
    try {
      console.log("Ejecutando");
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);
      console.log(workbook);

      var wsnames = workbook.SheetNames;

      // console.log(wsnames);

      let info = await wsnames.map((name) => {
        // console.log("datos de", name);
        let day = daysfiltrado.find((d) => d.name === name);
        return { id: day._id, data: XLSX.utils.sheet_to_json(workbook.Sheets[name]) };
      });
      setData(info);
      // console.log("info", info);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    DayHttp.SaveEmitionsBulk(data)
      .then((response) => {
        // throw { message: "test error" };
        setSnack({
          open: true,
          message: response.data.message,
        });
        setButton({
          open: response.data.data ? true : false,
          data: response.data.data ? response.data.data : "",
        });
        handleClose();
        setLoading(false);
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        console.log(error);
      });
    setTimeout(() => {
      setSnack({
        open: false,
        message: "",
      });
    }, 6000);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        Subir Excel
      </Button>
      {button.open && <DialogPrograms data={button.data} />}
      {snack.open && <Alert message={snack.message} Popen={snack.open} type={snack.type} />}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 3 }}>
            Selecciona un archivo
          </Typography>
          <TextField type={"file"} onChange={(e) => handleFile(e)}></TextField>
          <LoadingButton
            variant="contained"
            sx={{ mt: 3 }}
            onClick={() => handleSubmit()}
            loading={loading}
          >
            Guardar
          </LoadingButton>
        </Box>
      </Modal>
    </div>
  );
}
