import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Form from "./form";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import EstacionHttp from "../../../services/api/estacionService";
import Alert from "../../../components/alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Title = "Editar Estación";

const ButtonForm = {
  text: "Editar",
  icon: <EditOutlinedIcon />,
  textCancel: "Cancelar",
};

export default function EditButtonModal({ row, refreshFunction }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "success",
  });
  const [data_send, setData_send] = useState({ ...row });
  const handleEdit = () => {
    EstacionHttp.update(data_send._id, {
      name: data_send.name,
      description: data_send.description,
      url: data_send.url,
      active: data_send.active,
    })
      .then((response) => {
        // throw { message: "test error" };
        setSnack({
          open: true,
          message: response.data.message,
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        handleClose();
        refreshFunction();
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        console.log(error);
      });
  };
  return (
    <div>
      <IconButton sx={{ width: "30px", height: "30px", mr: 2 }} onClick={handleOpen}>
        <Tooltip title="Editar">
          <IconButton>
            <EditIcon sx={{ color: "rgb(240,176,0)" }} />
          </IconButton>
        </Tooltip>
      </IconButton>
      {snack.open && <Alert message={snack.message} Popen={snack.open} type={snack.type} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Form
              title={Title}
              ButtonForm={ButtonForm}
              data_send={data_send}
              setData_send={setData_send}
              handleAction={handleEdit}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
