import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import FormAdd from "./form";
import Alert from "../../../components/alert";
import EstacionHttp from "../../../services/api/estacionService";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Title = "Agregar Estacion";

const ButtonForm = {
  text: "Guardar",
  icon: <SaveIcon />,
  textCancel: "Cancelar",
};

export default function AddButtonModal({ refreshFunction }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setData_send({
      name: null,
      description: "",
      url: null,
      active: false,
    });
  };
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "success",
  });
  const [data_send, setData_send] = useState({
    name: null,
    description: "",
    url: null,
    active: false,
  });

  const handleSave = async () => {
    EstacionHttp.create(data_send)
      .then((response) => {
        // throw { message: "test error" };
        setSnack({
          open: true,
          message: response.data.message,
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        handleClose();
        refreshFunction();
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        console.log(error);
      });
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h4" color="rgb(64,63,63)">
          Estaciones
        </Typography>
        <Button variant="contained" sx={{ ml: "auto" }} onClick={handleOpen}>
          <AddIcon />
          Agregar
        </Button>
        {snack.open && <Alert message={snack.message} Popen={snack.open} type={snack.type} />}
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormAdd
              data_send={data_send}
              setData_send={setData_send}
              handleAction={handleSave}
              title={Title}
              ButtonForm={ButtonForm}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
