import http from "./index";

const getAll = () => {
  return http.get("./stations");
};

const getOne = (id) => {
  return http.get(`./stations/${id}`);
};

const create = (data_send) => {
  return http.post("./stations", data_send);
};

const update = (id, data_send) => {
  return http.put(`./stations/${id}`, data_send);
};

const remove = (id) => {
  return http.delete(`./stations/${id}`);
};

const estacionService = {
  getAll,
  getOne,
  create,
  update,
  remove,
};

export default estacionService;
