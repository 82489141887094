import { PrivateRoute } from "../components/PrivateRoute";
import authService from "../services/api/authService";

const tokenExpired = (error) => {
  if (error.response.status === 401) {
    authService.logout();
    return <PrivateRoute />;
  }
};

export default tokenExpired
