import * as React from "react";
import enLocale from "date-fns/locale/en-US";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import ProgramHttp from "../../../services/api/programaService";
import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { dateToDoubletime, stringPrettyToDateTime } from "../../../helpers/timeDateDouble";

export default function Form({ handleAction, handleClose, title, data, ButtonForm }) {

  const decimalCount = num => {
    const numStr = String(num);
    if (numStr.includes('.')) {
       return numStr.split('.')[1].length;
    };
    return 0;
 }

  const getTime = (time) => {
    console.log(time)
    let timeToString = time.toString()
    /*Agregar :00 si es un numero sin decimal */
    if(!timeToString.includes(".")) {
      timeToString = timeToString + ":00"
    }
    /*Agregar un cero al final si es un numero con un solo decimal */
    if(decimalCount(time) === 1) {
      timeToString = timeToString + "0"
    }    
    let timeformat = timeToString.replace(".", ":")
    let resp = time !== undefined && time !== null
      ? stringPrettyToDateTime(timeformat)
      : dateToDoubletime(new Date());
    return resp
  }

  const [programs, setPrograms] = useState([]);
  const [formData, setFormData] = useState({
    ...data,
    time_start: dateToDoubletime(data ? getTime(data && data.time_start) : new Date()),
    time_end: dateToDoubletime(data ? getTime(data && data.time_end) : new Date()),
  });
  const [hours, setHours] = useState({
    time_start: data ? getTime(data && data.time_start) : new Date(),
    time_end: data ? getTime(data && data.time_end) : new Date(),
  });
  const [errors, setErrors] = useState(false)

  const handleChangeFormData = ({ target }) =>
    setFormData({ ...formData, [target.name]: target.value });

  const handleChangeTimeStart = (newTime) => {
    setHours({ ...hours, time_start: newTime });
    setFormData({ ...formData, time_start: dateToDoubletime(newTime) });
  };

  const handleChangeTimeEnd = (newTime) => {
    setHours({ ...hours, time_end: newTime });
    setFormData({ ...formData, time_end: dateToDoubletime(newTime) });
  };

  const action = async () => {
    console.log("action", formData)
    setErrors(false)
    if(formData.program_id === undefined || formData.time_start === undefined || formData.time_end === undefined) {
      setErrors(true)
      return false
    }
    await handleAction({ formData });
  };

  const ampm = undefined;

  const getData = async () => {
    const {
      data: { data: programs },
    } = await ProgramHttp.getAll();
    setPrograms(programs);
  };

  useEffect(() => {
    async function fetchData() {
      await getData();
    }
    fetchData();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <Stack spacing={3}>
        <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
          {title}{" "}
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" onChange={() => {}}>
            Programa *
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={formData.program_id && programs.length > 0 ? formData.program_id : ""}
            displayEmpty
            label="Programa"
            name={"program_id"}
            onChange={handleChangeFormData}
          >
            {programs.map((program, index) => (
              <MenuItem key={index} value={program._id}>
                {program.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>{" "}
        <TimePicker
          name={"time_start"}
          label={"Hora inicial"}
          value={hours.time_start ?? new Date()}
          onChange={handleChangeTimeStart}
          renderInput={(params) => <TextField {...params} />}
          ampm={ampm}
        />
        <TimePicker
          name={"time_end"}
          label={"Hora final"}
          value={hours.time_end ?? new Date()}
          onChange={handleChangeTimeEnd}
          renderInput={(params) => <TextField {...params} />}
          ampm={ampm}
        />
        { errors &&
        <Typography variant="body1" sx={{ fontSize: 14, color: "red" }}>
          Todos los campos son obligatorios
        </Typography>
        }
        <Box sx={{ display: "flex" }}>
          <LoadingButton
            size="small"
            color="secondary"
            onClick={() => handleClose()}
            variant="contained"
            sx={{ ml: 27, mt: 1 }}
          >
            {ButtonForm.textCancel}
          </LoadingButton>
          <LoadingButton
            size="small"
            color="secondary"
            onClick={action}
            loadingPosition="start"
            startIcon={ButtonForm.icon}
            variant="contained"
            sx={{ ml: "auto", mt: 1 }}
          >
            {ButtonForm.text}
          </LoadingButton>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
}
